<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>

   <!-- This is where the logo comes.    -->
      <CHeaderBrand to="/">
        <CNavLink href="/#/dashboard" style="cursor: pointer;">
          <img class="w-100" :src="ccMainLogo" alt="Saras logo" />
        </CNavLink>
      </CHeaderBrand>

      <CHeaderNav class="d-sm-flex ms-auto me-0">

          <!--   Notification button    -->
          <!-- <CNavItem class="mb-0 mt-0 p-0">
            <CHeaderToggler>
              <CButton color="warning" @click="$store.commit('toggleAside')">
              <CIcon icon="cil-bell" />
              </CButton>
            </CHeaderToggler>
          </CNavItem> -->

          <!--   Log out button   -->
          <CNavItem class="mb-0 mt-1 p-0">
            <CButton color="danger" @click="logoutUser()" variant="outline">
            <CIcon icon="cil-exit-to-app" />
            </CButton>
          </CNavItem>

      </CHeaderNav>

    </CContainer>
  </CHeader>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import { logoNegative } from '@/assets/brand/logo-negative'
import { closeSession } from '@/store/session'

import ccMainLogo from '@/assets/globetrotter/logo-with-title.svg'

export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      ccMainLogo,
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  methods: {
    logoutUser() {
      closeSession(this.$router)
    }
  }
}
</script>
