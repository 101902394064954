export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },

  // APPS
  {
    component: 'CNavTitle',
    name: 'APPS',
  },
  {
    component: 'CNavItem',
    name: 'SARAS',
    to: '/apps/saras',
    icon: 'cil-blur',
  },
]
