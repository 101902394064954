import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibGoogle,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibCcStripe,
  cibCcPaypal,
  cibCcApplePay,
  cibCcAmex,
} from '@coreui/icons'
import { cifUs, cifBr, cifIn, cifFr, cifEs, cifPl } from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilBookmark,
  cilBold,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilCloudDownload,
  cilCloudUpload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilFile,
  cilGlobeAlt,
  cilGrid,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSearch,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilXCircle,
} from '@coreui/icons'

/* Custom imports goes here */ 
import {
  cilBolt,
  cilArrowThickFromLeft,
  cilArrowThickFromRight,
  cilAccountLogout,
  cilX,
  cilAddressBook,
  cilExitToApp,
  cilLibraryBuilding,
  cilBuilding,
  cilDoor,
  cilClearAll,
  cilChevronCircleRightAlt,
  cilCaretRight,
  cilBarcode,
  cilStorage,
  cilPlus,
  cilAppsSettings,
  cilFolderOpen,
  cilFeaturedPlaylist,
  cilThumbUp,
  cilBraille,
  cilBlurLinear,
  cilDataTransferDown,
  cilFlightTakeoff,
  cilGraph,
  cilLink,
  cilActionUndo,
  cilBlur,
  cilRowing,
  cilImage,
  cilHandPointDown,
  cilTruck,
  cilInfo,
  cilXcircle,
  cilRunning,
  cilReload,
  cilMoney,
  cilApps,
  cilApplications,
  cilTransfer,
  cilExternalLink,
  cilThumbDown,
  cilSend,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilBookmark,
    cilBold,
    cilCalculator,
    cilCalendar,
    cilCart,
    cilCloudDownload,
    cilCloudUpload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilGlobeAlt,
    cilGrid,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilItalic,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilSearch,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserPlus,
    cilXCircle,
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
  },

  // Custom exports goes here
  {
    cilBolt,
    cilArrowThickFromLeft,
    cilArrowThickFromRight,
    cilAccountLogout,
    cilX,
    cilAddressBook,
    cilExitToApp,
    cilLibraryBuilding,
    cilBuilding,
    cilDoor,
    cilClearAll,
    cilChevronCircleRightAlt,
    cilCaretRight,
    cilBarcode,
    cilStorage,
    cilPlus,
    cilAppsSettings,
    cilFolderOpen,
    cilFeaturedPlaylist,
    cilThumbUp,
    cilBraille,
    cilBlurLinear,
    cilDataTransferDown,
    cilFlightTakeoff,
    cilGraph,
    cilLink,
    cilActionUndo,
    cilBlur,
    cilRowing,
    cilImage,
    cilHandPointDown,
    cilTruck,
    cilInfo,
    cilXCircle,
    cilRunning,
    cilReload,
    cilMoney,
    cilApps,
    cilApplications,
    cilTransfer,
    cilExternalLink,
    cilThumbDown,
    cilSend,
  }
)
